<template>
  <div class="swiperPop">
    <div class="loop">
      <swiper ref="mySwiper" :options="swiperOption" class="loop_c">
        <swiper-slide v-for="item in imgList" :key="item.index">
          <img :src="item.imgUrl" alt="" />
        </swiper-slide>
        <div class="swiper-button-prev1" slot="button-prev"></div>
        <div class="swiper-button-next1" slot="button-next"></div>
      </swiper>
    </div>
    <div class="close" @click="closeSwiperPop"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper"
export default {
  name: 'swiperpop',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return ({
      imgList: [
        {
          imgUrl: require('../assets/img/swiper/brand_1.jpg')
        }, {
          imgUrl: require('../assets/img/swiper/brand_2.jpg')
        }, {
          imgUrl: require('../assets/img/swiper/swiper3.jpg')
        }
      ],
      swiperOption: {
        spaceBetween: -30,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        centeredSlides: true,
        slidesPerView: 3,
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
      },
    })
  },
  methods: {
    closeSwiperPop() {
      this.$parent.showSwiperPop = false
    },
    changeSwiperIndex(index){
      //将指定元素移动到数组首位
      let imgUrl = this.imgList[index]
      this.imgList.splice(index,1)
      this.imgList.unshift(imgUrl)
    }
  }
}
</script>

<style scoped lang="scss">
.swiperPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #000000;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 20px;

  .loop {
    width: 1200px;
    height: 480px;
    outline: 1px solid;

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      cursor: pointer;
      text-align: center;
      font-size: 18px;
      -webkit-transition: transform 1.0s;
      -moz-transition: transform 1.0s;
      -ms-transition: transform 1.0s;
      -o-transition: transform 1.0s;
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }

    .swiper-slide-active,
    .swiper-slide-duplicate-active {
      -webkit-transform: scale(1);
      transform: scale(1);
      z-index: 9;
    }

    .swiper-slide img {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 10px solid #fff;
    }

    .swiper-button-prev1,
    .swiper-button-next1 {
      color: rgb(88, 105, 133);
      position: fixed;
      transform: translateY(-50%);
      top: 50%;
      width: 60px;
      height: 80px;
      background: url(../assets/img/swiper/btn_next_prev.png) no-repeat;
      cursor: pointer;
    }

    .swiper-button-prev1 {
      left: 30px;
      background-position: 0 0;
    }

    .swiper-button-prev1:hover {
      background-position: 0 -243px;
    }

    .swiper-button-next1 {
      right: 30px;
      background-position: 0 -80px;
    }

    .swiper-button-next1:hover {
      right: 30px;
      background-position: 0 -161px;
    }
  }

  .close {
    position: fixed;
    cursor: pointer;
    top: 50px;
    right: 50px;
    width: 34px;
    height: 34px;
    background: url(../assets/img/swiper/btn_close.png);
  }

}
</style>