<template>
  <div ref="animation" :class="['swiper_img_list']">
    <div v-for="(item,index) in brand_img_list" :key="index" @click="changeShow(index)">
      <img :src="item.imgUrl" alt="">
    </div>
    <SwiperPop v-if="showSwiperPop" ref="swiperPop"></SwiperPop>
  </div>
</template>

<script>
import SwiperPop from '@/components/SwiperPop'
import { mixins } from '@/mixin'
export default{
  name:'swiperImgList',
  components:{ SwiperPop },
  mixins:[mixins],
  data(){
    return({
      imgList: [
        {
          imgUrl: require('../assets/img/swiper/brand_1.jpg')
        }, {
          imgUrl: require('../assets/img/swiper/brand_2.jpg')
        }, {
          imgUrl: require('../assets/img/swiper/swiper3.jpg')
        }
      ],
      brand_img_list:[
        {
          imgUrl:require('../assets/img/swiper/brand_1.jpg')
        },{
          imgUrl:require('../assets/img/swiper/brand_2.jpg')
        },{
          imgUrl:require('../assets/img/swiper/swiper3.jpg')
        }
      ],
      showSwiperPop:false
    })
  },
  methods:{
    changeShow(index){
      this.showSwiperPop = !this.showSwiperPop
      // setTimeout(()=>{
      //   this.$refs.swiperPop.changeSwiperIndex(index)
      // },200)
    }
  }
}
</script>

<style scoped lang="scss">
.swiper_img_list{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  img{
    width: 385px;
    height: 480px;
  }
}
</style>