<template>
  <div class="about">
    <Header></Header>
    <BaseBanner></BaseBanner>
    <div class="about_main">
      <div class="about_content website_wrapper">
        <div class="card_list">
          <CardList></CardList>
        </div>
        <div class="info_card">
          <InfoCard :cardInfo="aboutCardInfo"></InfoCard>
        </div>
        <div class="swiper_list">
          <SwiperImgList></SwiperImgList>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import BaseBanner from '@/components/BaseBanner'
import CardList from '@/components/CardList'
import InfoCard from '@/components/InfoCard'
import SwiperImgList from '@/components/SwiperImgList'
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
export default {
  name: 'About',
  components: { BaseBanner, CardList, InfoCard, SwiperImgList,Header ,Footer},
  data() {
    return ({
      aboutCardInfo: {
        infoPic: require('../assets/img/card/index_card_1.jpg'),
        order: 'A',
        title: '贴近科室业务，提高医疗服务水平',
        message: `XXX有限公司创建于1982年，现有职工500余名。
多年来，在上级各部门的关心、支持下，在总经理的率领下，以技术创新为核心动力，始终秉承“诚信、专业、创新”的理念，怀着不断“超越自我”的激情，艰苦创业、开拓奋进，企业从小到大、稳步发展，由一个名不见经传的街道小企业，发展为高端生产不锈钢医用消毒器皿、器具、器械、护理车等系列产品的专业工厂，现已成为全国闻名的生产经营医疗器械的集科研、生产、经营、服务于一体的专业化集团。`,
        moreUrl: '',
        reverse: true,
        more: false
      }
    })
  }
}
</script>

<style scoped lang="scss">
.about {
  .about_main {
    .about_content {
      position: relative;
      height: 1655px;

      .info_card {
        position: absolute;
        top: 300px;
        left: 0;
        width: 1200px;
      }

      .swiper_list {
        position: absolute;
        bottom: 80px;
        left: 0;
      }
    }
  }
}
.cardList {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 121px;
  left: 1px;
  width: 1200px;
  animation: 0s ease 0s 1 normal none running none;
}
</style>